<template>
  <div>
    <el-row :gutter="20" type="flex">
      <el-col :span="6">
        <el-card class="headerNav">
          <div slot="header" class="clearfix">
            <span class="title">数据导入/导出</span>
          </div>
          <div class="setting">
            <div
              v-for="(item, index) in headerData"
              :key="item"
              class="setttingItem"
              :class="{ active: currentIndex == index }"
              @click="changeNav(index)"
            >
              {{ item }}
              <div class="space"></div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card>
          <div slot="header" class="clearfix">
            <span class="title">数据看板</span>
          </div>
          <div>
            <el-form ref="form" :model="form" label-width="20px">
              <div class="formText">1.选择导入考试人员所考的证书，并下载模板</div>
              <div style="display:flex">
                <el-form-item label="" prop="productId">
                  <el-select
                    v-model="form.productId"
                    placeholder="请选择证书商品"
                    @change="changeProduct"
                  >
                    <el-option
                      v-for="item in selectList"
                      :key="item.productId"
                      :label="item.productName"
                      :value="item.productId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="productSkuCode">
                  <el-select
                    v-model="form.productSkuCode"
                    multiple
                    placeholder="请选择服务"
                    @change="changeSkuProduct"
                  >
                    <el-option
                      v-for="item in levelList"
                      :key="item.skuCode"
                      :label="item.skuValue"
                      :value="item.skuCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-button style="margin-left:60px" type="primary" @click="downLoad"
                  >下载模板</el-button
                >
              </div>
              <div class="formText">2.上传考试成绩文件</div>
              <el-form-item label="" prop="file">
                <el-upload
                  class="upload-batch"
                  drag
                  action=""
                  :limit="1"
                  :auto-upload="false"
                  :file-list="fileList"
                  accept=".xlsx,.xls"
                  :on-change="handleChange"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
              </el-form-item>
              <div class="formText">大小5MB内，支持.xlsx.xls，只能上传一个文件</div>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">确定上传</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <div v-if="title == '上传成功'">上传成功，成功更新{{ dialogContent }}条信息</div>
      <div v-else>
        上传失败，导致失败数据有：
        <div v-for="(item, index) in dialogList" :key="index">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { importResults, uploadResults, productSkuList } from '@/api/certificate'
import to from 'await-to'
export default {
  data() {
    return {
      headerData: ['导入成绩'],
      currentIndex: 0,
      form: {},
      fileList: [],
      dialogVisible: false,
      selectList: [],
      levelList: [],
      productId: '',
      productSkuCodes: '',
      title: '',
      dialogContent: '',
      dialogList: [],
    }
  },
  created() {
    this.productSkuListData()
  },
  methods: {
    changeNav(i) {
      this.currentIndex = i
    },
    changeProduct(e) {
      this.productId = e
      this.form.productSkuCode = []
      this.productSkuCodes = []
      this.selectList.map(v => {
        if (e == v.productId) {
          this.levelList = v.skuInfos
        }
      })
    },
    changeSkuProduct(e) {
      console.log(e)
      this.productSkuCodes = e
    },
    async productSkuListData() {
      const [res, err] = await to(productSkuList({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectList = res.data
    },
    async onSubmit() {
      if (!this.fileList.length) return this.$message.warning('请先选择文件')
      const params = new FormData()
      params.append('fileTypeCode', 'F002')
      params.append('file', this.fileList[0].raw)
      params.append('fileName', this.fileList[0].name)
      const [res, err] = await to(uploadResults(params))
      if (err) {
        if (err.code == 1000000) {
          this.title = '上传失败'
          this.dialogList = err.data
          this.dialogVisible = true
        }
        return this.$message({ type: 'error', message: err.msg })
      }
      this.title = '上传成功'
      this.dialogContent = res.data
      this.dialogVisible = true
    },
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
    },
    async downLoad() {
      if (!this.form.productId || this.form.productId == '')
        return this.$message.warning('请选择证书商品')
      if (!this.form.productSkuCode || this.form.productSkuCode == '')
        return this.$message.warning('请选择服务')
      const [res, err] = await to(
        importResults({
          productId: this.productId,
          productSkuCodes: this.productSkuCodes,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '模板.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>

<style scoped lang="scss">
.headerNav {
  height: 100%;
  .setting {
    cursor: pointer;
    font-size: 16px;
    .setttingItem {
      padding-left: 20px;
      line-height: 50px;
      position: relative;
      .space {
        position: absolute;
        left: 0;
        top: 13px;
        width: 4px;
        height: 24px;
        background-color: #ff7b33;
        display: none;
      }
    }
    .active {
      color: #ff7b33 !important;
      .space {
        display: block !important;
      }
    }
  }
}
.formText {
  padding-left: 20px;
  margin-bottom: 22px;
  font-size: 14px;
}
</style>
